<template>
  <div id="completed-component">
    <h2 class="slide-heading" v-html="heading" />

    <div v-if="description">
      <div class="description">
        <div v-html="description" />
      </div>
    </div>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'GenericNotFound',
  props: {
    slide: null,
  },
  computed: {
    description() {
      const desc = 'Our walk-through guide doesn\'t offer PDF filing for this ' +
        'state—either the state requires special online filing, or it’s a complex form that ' +
        'needs additional care.'
      return DOMPurify.sanitize(desc)
    },
    heading() {
      return `This paperwork is a mystery our guide can’t quite solve yet. `
    },
  },
}
</script>
